import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact-info">
        <h3>Contact</h3>
        <p>Riley: 614-537-5855</p>
        <p>If you'd like to share your story, or help with building a platform to reach our goal, please call or text the number above. All info you share will remain anonymous.</p>
      </div>
    </footer>
  );
};

export default Footer;
