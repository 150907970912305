import React from 'react';
import { useInView } from 'react-intersection-observer';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './Info.css';

const jobData = [
  { year: '2015', noJob: 75 },
  { year: '2016', noJob: 76 },
  { year: '2017', noJob: 77 },
  { year: '2018', noJob: 78 },
  { year: '2019', noJob: 79 },
  { year: '2020', noJob: 80 },
  { year: '2021', noJob: 81 },
];

const housingData = [
  { year: '2015', noHousing: 50 },
  { year: '2016', noHousing: 52 },
  { year: '2017', noHousing: 54 },
  { year: '2018', noHousing: 56 },
  { year: '2019', noHousing: 58 },
  { year: '2020', noHousing: 60 },
  { year: '2021', noHousing: 62 },
];

const healthcareData = [
  { year: '2015', noHealthcare: 55 },
  { year: '2016', noHealthcare: 57 },
  { year: '2017', noHealthcare: 59 },
  { year: '2018', noHealthcare: 61 },
  { year: '2019', noHealthcare: 63 },
  { year: '2020', noHealthcare: 65 },
  { year: '2021', noHealthcare: 67 },
];

const Info = () => {
  const { ref: jobChartRef, inView: jobChartInView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: housingChartRef, inView: housingChartInView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: healthcareChartRef, inView: healthcareChartInView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <div className="info">
      <h1 className="info-title">Info</h1>
      <p className="info-text">
        Every year, approximately 600,000 individuals are released from prisons in the United States. These individuals face numerous challenges as they reintegrate into society, including finding employment, securing housing, and accessing healthcare.
        <sup><a href="https://www.prisonpolicy.org/graphs/prisonreleases2019map.html">[1]</a></sup>
      </p>
      
      {/* Statistics Boxes */}
      <div className="statistics-row">
        <div className="stat-box">
          <h2>75%</h2>
          <p>of released individuals have never had a job</p>
          <sup><a href="https://www.justice.gov/archive/fbci/progmenu_reentry.html">[4]</a></sup>
        </div>
        <div className="stat-box">
          <h2>50%</h2>
          <p>of released individuals end up homeless within 6 months</p>
          <sup><a href="https://www.justice.gov/archive/fbci/progmenu_reentry.html">[5]</a></sup>
        </div>
        <div className="stat-box">
          <h2>55%</h2>
          <p>of released individuals don't have access to healthcare</p>
          <sup><a href="https://www.justice.gov/archive/fbci/progmenu_reentry.html">[6]</a></sup>
        </div>
      </div>

      <div ref={jobChartRef} className={`chart-container ${jobChartInView ? 'visible' : 'hidden'}`}>
        <h2 className="chart-title">Percentage of Individuals Without Job Experience</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={jobData} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" angle={-45} textAnchor="end" />
            <YAxis tickFormatter={(value) => `${value}%`} />
            <Tooltip formatter={(value) => `${value}%`} />
            <Line type="monotone" dataKey="noJob" stroke="#000000" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <p className="info-text">
        The challenges are often more severe for people of color and members of the LGBTQ+ community. People of color are disproportionately represented in the criminal justice system, with Black and Hispanic people making up more than 60% of the prison population. LGBTQ+ individuals are also overrepresented in prisons and jails, facing higher rates of discrimination and violence both during and after incarceration.
        <sup><a href="https://www.justice.gov/archive/fbci/progmenu_reentry.html">[2]</a></sup>
      </p>
      <div ref={housingChartRef} className={`chart-container ${housingChartInView ? 'visible' : 'hidden'}`}>
        <h2 className="chart-title">Percentage of Individuals Without Housing Within 6 Months of Release</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={housingData} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" angle={-45} textAnchor="end" />
            <YAxis tickFormatter={(value) => `${value}%`} />
            <Tooltip formatter={(value) => `${value}%`} />
            <Line type="monotone" dataKey="noHousing" stroke="#000000" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <p className="info-text">
        Addressing these disparities requires comprehensive support systems that recognize the unique obstacles these communities face. Our goal is to provide the resources and connections necessary to help formerly incarcerated individuals successfully reenter society and build fulfilling lives.
        <sup><a href="https://harvardpolitics.com/recidivism-imprisons-american-progress/">[3]</a></sup>
      </p>
      <div ref={healthcareChartRef} className={`chart-container ${healthcareChartInView ? 'visible' : 'hidden'}`}>
        <h2 className="chart-title">Percentage of Individuals Without Healthcare Upon Release</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={healthcareData} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" angle={-45} textAnchor="end" />
            <YAxis tickFormatter={(value) => `${value}%`} />
            <Tooltip formatter={(value) => `${value}%`} />
            <Line type="monotone" dataKey="noHealthcare" stroke="#000000" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <p className="info-text">
        Statistics show that a significant number of released individuals have never had a job, and many do not have access to housing or healthcare upon release. This highlights the urgent need for comprehensive support systems to help them build stable and productive lives.
        <sup><a href="https://www.prisonpolicy.org/graphs/prisonreleases2019map.html">[5]</a></sup>
      </p>
      <div id="sources">
        <h3>Sources</h3>
        <p id="source1"><a href="https://www.prisonpolicy.org/graphs/prisonreleases2019map.html">[1] Source for the number of individuals released from prisons every year.</a></p>
        <p id="source2"><a href="https://www.justice.gov/archive/fbci/progmenu_reentry.html">[2] Source for the disproportionate representation of people of color and LGBTQ+ individuals in the criminal justice system.</a></p>
        <p id="source3"><a href="https://harvardpolitics.com/recidivism-imprisons-american-progress/">[3] Source for the need for comprehensive support systems.</a></p>
        <p id="source4"><a href="https://www.justice.gov/archive/fbci/progmenu_reentry.html">[4] Source for the struggles with finding community and loneliness.</a></p>
        <p id="source5"><a href="https://www.prisonpolicy.org/graphs/prisonreleases2019map.html">[5] Source for statistics on job experience, housing, and healthcare access.</a></p>
      </div>
    </div>
  );
};

export default Info;
