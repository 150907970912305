import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const text = `Many formerly incarcerated individuals struggle with finding community and often face loneliness as they reenter society. 

Concrete's goal is to bridge the gap between released individuals and the services they need, including access to employers, housing, healthcare, and community support, to ensure they do not face these challenges alone.`;

  return (
    <div className="home">
      <div className="main-content">
        <div className="overlay"></div>
        <div className="content">
          <h1>Concrete</h1>
          <p>Supporting Reentry into Society</p>
          <div className="button-container">
            <Link to="/resources" className="btn">View Resources</Link>
          </div>
        </div>
      </div>
      <div className="text-container">
        {text.split('\n').map((paragraph, idx) => (
          <p key={idx}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

export default Home;
