import React from 'react';
import './Resources.css';

const Resources = () => {
  return (
    <div className="resources">
      <section>
        <h2>Employment</h2>
        <ul>
          <li><a href="https://www.ceoworks.org" target="_blank" rel="noopener noreferrer">Center for employment opportunities</a></li>
          <li><a href="https://www.indeed.com/q-Second-Chance-jobs.html" target="_blank" rel="noopener noreferrer">Indeed – Second Chance Jobs</a></li>
          <li><a href="https://www.careeronestop.org/ExOffender" target="_blank" rel="noopener noreferrer">CareerOneStop – Ex-Offender Resources</a></li>
          <li><a href="http://www.jailstojobs.org" target="_blank" rel="noopener noreferrer">Jails to Jobs</a></li>
        </ul>
      </section>

      <section>
        <h2>Housing</h2>
        <ul>
          <li><a href="https://www.hud.gov" target="_blank" rel="noopener noreferrer">HUD Reentry Program</a></li>
          <li><a href="https://www.transitionalhousing.org" target="_blank" rel="noopener noreferrer">Transitional Housing Programs Directory</a></li>
        </ul>
      </section>

      <section>
        <h2>Community Support</h2>
        <ul>
          <li><a href="https://www.prisonfellowship.org" target="_blank" rel="noopener noreferrer">Prison Fellowship</a></li>
          <li><a href="https://www.rootandrebound.org" target="_blank" rel="noopener noreferrer">Root & Rebound</a></li>
          <li><a href="https://www.fortunesociety.org" target="_blank" rel="noopener noreferrer">The Fortune Society</a></li>
          <li><a href="https://nationalreentryresourcecenter.org" target="_blank" rel="noopener noreferrer">National Reentry Resource Center</a></li>
        </ul>
      </section>
    </div>
  );
};

export default Resources;
