import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <span className="navbar-brand" onClick={() => navigate('/')}>Concrete</span> {/* Navigate to Home */}
      </div>
      <ul className="navbar-right">
        <li><Link to="/info">Info</Link></li> {/* Updated Info link */}
      </ul>
    </nav>
  );
};

export default Navbar;
